import { navigate } from "gatsby";
import { stringify } from "querystring";
import React, { useState, useEffect, useContext } from "react";
import Activity, { instanceOfActivity } from "shared-models/build/activities";
import Country from "shared-models/build/countries";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { sendPostRequest, toastError } from "../../util/util";


const ModifyCountry = () => {
    const [activityMap, setActivityMap] = useState<{ [key: number]: Activity }>({});
    const [country, setCountry] = useState<Country>({
        Id: 0,
        Name: "",
        Description: "",
        CountryPictureUrl: "",
        Verified: false,
        Settings: {},
        FlagUrl: "",
    });
    const [selectedActivities, setSelectedActivities] = useState<Activity[]>([]);
    const user = useContext(FirebaseAuthContext);
    const [initCountrySettings, setInitCountrySettings] = useState({});
    const [settingsVal, setSettingsVal] = useState<string>("");

    useEffect(() => {
        if (user !== null) {
            getActivities();
        }
    }, [user]);

    useEffect(() => {
        setSettingsVal(JSON.stringify(country.Settings));
    }, [country])


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const data = params.get("data");
        if (data === null) return;
        const parsedData = JSON.parse(data);

        if (!("Country" in parsedData)) return;

        let activities = {};
        if ("CountryActivities" in parsedData) {
            activities = parsedData.CountryActivities;
        }

        const country = parsedData.Country;

        setCountry(country);
        setInitCountrySettings(country.Settings);

        const initSelectedActivities = Object.keys(activities).reduce((arr: Activity[], actId) => {
            const numericalId = +String(actId);
            const act = activityMap[numericalId]; 
            if (!act) return arr;
            return [
                ...arr,
                act,
            ]
        }, []);

        setSelectedActivities(initSelectedActivities);

    }, [activityMap]);

    const getActivities = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/activity/get-activities", {}, "", "Failed to get activities");
        if (err !== null) {
            console.log({ resp, err });
        };

        if ("Activities" in resp) {
            const initActivityMap = resp.Activities?.reduce((obj : { [key: number]: Activity }, activity : Activity) => {
                return {
                    ...obj,
                    [activity.Id]: activity
                }
            }, {});
            setActivityMap(initActivityMap);
        } else {
            setActivityMap({});
        }
    }

    const handleCountryChange = (key: string, value: string | boolean | number) => {
        setCountry({
            ...country,
            [key]: value,
        });
    };

    const handleSelectCountry = (id : string) => {
        const numericalId = +String(id);
        const tagFromId = Object.values(activityMap).find((val) => val.Id === numericalId);
        const tagExits = selectedActivities.findIndex((val) => val.Id === numericalId);
        if (tagExits >= 0) {
            toastError("Tag already added");
            return;
        }
        if (tagFromId) {
            setSelectedActivities([
                ...selectedActivities,
                tagFromId,
            ])
        }
    }

    const removeActivity = (index: number) => {
        const duplicate = [...selectedActivities];
        duplicate.splice(index, 1);
        setSelectedActivities(duplicate);
    }

    const saveCountry = async () => {
        let parsed = {};
        try {
            parsed = JSON.parse(String(settingsVal));
        } catch(e) {
            toastError("Failed to parse JSON settings");
            return;
        }
        const tempCountry = { ...country };
        tempCountry.Settings = parsed;
        if (user === null) return;
        const [response, err] = await sendPostRequest(user, "/country/modify-country", { Country: tempCountry, CountryActivities: selectedActivities }, "Added the country.", "Failed to add the country.");
        if (err !== null) {
            console.log({ response, err });
        }
    }

    const deleteCountry = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/country/delete-country", { Country: country }, "Deleted the country.", "Failed to delete the country.");
        if (err !== null) {
            console.log({ resp, err });
        }
    }

    return (
        <div>
            <h2 className="tw-my-4 tw-font-bold tw-text-lg">Modify Country</h2>
            <div className="tw-my-4">
                <h3>Name</h3>
                <input 
                    className="tw-input"
                    value={country.Name}
                    onChange={(e) => handleCountryChange("Name", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Description</h3>
                <input 
                    className="tw-input"
                    value={country.Description}
                    onChange={(e) => handleCountryChange("Description", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Country Picture URL</h3>
                <input 
                    className="tw-input"
                    value={country.CountryPictureUrl}
                    onChange={(e) => handleCountryChange("CountryPictureUrl", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Flag URL</h3>
                <input 
                    className="tw-input"
                    value={country.FlagUrl}
                    onChange={(e) => handleCountryChange("FlagUrl", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Settings</h3>
                <input 
                    className="tw-input"
                    value={settingsVal}
                    onChange={(e) => setSettingsVal(e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Verified</h3>
                <input 
                    type="checkbox"
                    checked={country.Verified}
                    onChange={(e) => handleCountryChange("Verified", e.target.checked)}
                />
            </div>
            <div className="tw-my-4">
                <h3 className="tw-mt-4 tw-font-bold">Activities</h3>
                <select name="Tags" id="tags" multiple onChange={e => handleSelectCountry(e.target.value)} className="tw-min-h-[500px]">
                    {
                        Object.values(activityMap).map((activityObj) => {
                            if (!instanceOfActivity(activityObj)) return null;
                            return (
                                <option value={activityObj.Id} key={activityObj.Id}>
                                    {activityObj.Name}
                                </option>
                            )
                        })
                    }
                </select>
                <h3 className="tw-mt-4 tw-font-bold">Selected Activities</h3>
                <div className="tw-flex tw-flex-wrap tw-gap-8">
                {
                    selectedActivities?.map((st, index) => {
                        return (
                            <div className="tw-border tw-p-2" key={index}>
                                {st.Name} <button onClick={() => removeActivity(index)} className="tw-red-button tw-p-2">Remove</button>
                            </div>
                        )
                    })
                } 
                </div>
            </div>
            <div className="tw-mt-8">
                <button className="tw-white-button" onClick={() => {
                    saveCountry();
                    // navigate("/admin-team/activity")
                    //navigate("/admin-team/country")
                }}>
                    Save Country
                </button>
            </div>
            
            {
                country.Id > 0 ?
                <div className="tw-mt-8">
                    <button className="tw-red-button" onClick={() => {
                        deleteCountry();
                        handleCountryChange("Id", 0);
                    }}>
                        Delete Country
                    </button>
                </div>
                :
                null
            }
        </div>
    )
}

export default ModifyCountry;